import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import contactImg from "../assets/contact-img.png";
import submitImg from "../assets/sub-img.svg";
import Footer from "../Components/Footer";

function ContactUs() {
  const handlePhoneCall = () => {
    const phoneNumber = "8962594672";
    const phoneUrl = `tel:${phoneNumber}`;
    window.location.href = phoneUrl;
  };
  function openMailbox() {
    window.open("mailto:ajaynagwani@friendzo.in");
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <div className="contact-section">
        <div className="blue" data-aos="zoom-in-down">
          <br />
          <div className="orange" data-aos="zoom-in-left">
            <br />

            <div className="yellow" data-aos="zoom-in-right"></div>
          </div>
        </div>
        <div className="contact-section-left">
          <h6 className="contact-title">DISCOVER US</h6>
          <p className="contact-text">
            Flick is here to help you; <br /> Our experts are available to
            answer any questions you might have. We’ve got the answers.
          </p>
          {/* <h6 className="contact-title">Visit Us</h6>
          <p className="contact-text">
            Office no. G-02. Building 1, Ground Floor. <br /> Dubai Media City –
            Dubai
          </p> */}
          <h6 className="contact-title">Email US</h6>
          <p className="contact-text"  style={{cursor: "pointer"}} onClick={openMailbox}>ajaynagwani@friendzo.in</p>
          <h6 className="contact-title">Call US</h6>
          <p className="contact-text" style={{cursor: "pointer"}} onClick={handlePhoneCall}>
            +91 8962594672 <br />
          </p>
        </div>
        <div className="contact-section-right">
          <img src={contactImg} alt="" />
        </div>
      </div>
      <div className="contact-box">
        <div className="contact-wrap"></div>
        <div className="contact-form">
          <div className="contact-left">
            <h6 className="have-title">
              Have a Need <br /> Let’s diascuss
            </h6>
            <p className="have-text">
              Thank you for getting in touch! <br />
              Kindly. <br />
              Fill the form, have a great day!
            </p>
          </div>
          <div className="contact-right">
            <div className="form-inputs">
              <input
                type="text"
                placeholder="Your Name"
                className="contact-input"
              />
              <input
                type="text"
                placeholder="Your Email"
                className="contact-input"
              />
              <input
                type="text"
                placeholder="Phone Number"
                className="contact-input"
              />
              <select className="contact-input" name="" id="">
                <option value="India">India</option>
              </select>
              {/* <input
                type="text"
                placeholder="Company Name"
                className="contact-input"
              />
              <select className="contact-input" name="" id="">
                <option value="India">Interested in</option>
              </select> */}
              {/* <input
                type="text"
                placeholder="Message"
                className="contact-input"
              /> */}
            </div>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                marginTop: "40px",
              }}
            >
              <img src={submitImg} alt="" />
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
