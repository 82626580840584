import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function CookiePolicy() {
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <div className="terms-section">
        <h6 className="terms-title">Cookie Policy </h6>
      </div>
      <div className="term-content">
        <p className="terms-text">
          This is the Cookie Policy for www.friendzo.in, accessible from
          https://www.friendzo.in/
        </p>
        <h6 className="terms-c-title">What Are Cookies</h6>
        <p className="terms-text">
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the sites functionality.
        </p>

        <h6 className="terms-c-title">How We Use Cookies</h6>
        <p className="terms-text">
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <h6 className="terms-c-title">Disabling Cookies</h6>
        <p className="terms-text">
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of this site. Therefore
          it isrecommended that you do not disable cookies. This Cookies Policy
          was created with the help of the Cookies Policy Generator from
          CookiePolicyGenerator.com.
        </p>
        <h6 className="terms-c-title">The Cookies We Set</h6>
        <ul
          style={{
            flexDirection: "column",
            gap: "12px",
            listStyleType: "disc",
          }}
        >
          <li className="ul-li-text">
            Account related cookies <br />
            If you create an account with us then we will use cookies for the
            management of the signup process and general administration. These
            cookies will usually be deleted when you log out however in some
            cases they may remain afterwards to remember your site preferences
            when logged out.
          </li>
          <li className="ul-li-text">
            Login related cookies <br />
            We use cookies when you are logged in so that we can remember this
            fact. This prevents you from having to log in every single time you
            visit a new page. These cookies are typically removed or cleared
            when you log out to ensure that you can only access restricted
            features and areas when logged in.
          </li>
          <li className="ul-li-text">
            Email newsletters related cookies <br />
            This site offers newsletter or email subscription services and
            cookies may be used to remember if you are already registered and
            whether to show certain notifications which might only be valid to
            subscribed/unsubscribed users.
          </li>
          <li className="ul-li-text">
            Orders processing related cookies <br />
            This site offers e-commerce or payment facilities and some cookies
            are essential to ensure that your order is remembered between pages
            so that we can process it properly.
          </li>
          <li className="ul-li-text">
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
          <li className="ul-li-text">
            Surveys related cookies <br />
            From time to time we offer user surveys and questionnaires to
            provide you with interesting insights, helpful tools, or to
            understand our user base more accurately. These surveys may use
            cookies to remember who has already taken part in a survey or to
            provide you with accurate results after you change pages.
          </li>
          <li className="ul-li-text">
            Forms related cookies <br />
            When you submit data through a form such as those found on contact
            pages or comment forms cookies may be set to remember your user
            details for future correspondence.
          </li>
        </ul>
        <h6 className="terms-c-title">Third Party Cookies</h6>
        <p className="terms-text">
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
        </p>
        <ul
          style={{
            flexDirection: "column",
            gap: "12px",
            listStyleType: "disc",
          }}
        >
          <li className="ul-li-text">
            This site uses Google Analytics which is one of the most widespread
            and trusted analytics solution on the web for helping us to
            understand how you use the site and ways that we can improve your
            experience. These cookies may track thingssuch as how long you spend
            on the site and the pages that you visit so we can continue to
            produce engaging content. <br />
            For more information on Google Analytics cookies, see the official
            Google Analytics page.
          </li>
          <li className="ul-li-text">
            Third party analytics are used to track and measure usage of this
            site so that we can continue to produce engaging content. These
            cookies may track things such as how long you spend on the site or
            pages you visit which helps us to understand how we can improve the
            site for you.
          </li>
          <li className="ul-li-text">
            The Google AdSense service we use to serve advertising uses a
            DoubleClick cookie to serve more relevant ads across the web and
            limit the number of times that a given ad is shown to you. <br />{" "}
            For more information on Google AdSense see the official Google
            AdSense privacy FAQ.
          </li>
          <li className="ul-li-text">
            We also use social media buttons and/or plugins on this site that
            allow you to connect with your social network in various ways. For
            these to work the following social media sites including; "List the
            social networks whose features you have integrated with your
            site?:12", will set cookies through our site which may be used to
            enhance your profile on their site or contribute to the data they
            hold for various purposes outlined in their respective privacy
            policies.
          </li>
        </ul>
        <h6 className="terms-c-title">More Information</h6>
        <p className="terms-text m-0">
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site.
        </p>
        <p className="terms-text m-0">
          For more general information on cookies, please read the "Cookies"
          article from the Privacy Policy Generator.
        </p>
        <p className="terms-text m-0">
          However if you are still looking for more information then you can
          contact us through one of our preferred contact methods:
        </p>
        <ul
          style={{
            flexDirection: "column",
            gap: "12px",
            listStyleType: "disc",
            marginBottom: "80px"
          }}
        >
          <li className="ul-li-text m-0">Email:</li>
          <li className="ul-li-text m-0">By visiting this link:</li>
          <li className="ul-li-text m-0">Phone:</li>
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default CookiePolicy;
