import React from "react";
import logo from "../assets/footer-log.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer>
        <div className="footer-col">
          <div className="footer">
            <div className="footer1">
              <Link to="/">
                <img src={logo} className="home-logo" alt="Logo" />
              </Link>
              {/* <h1>Friendzo</h1> */}
              <br />
              <br />
              <p>
                {/* Our Friendzo also gets you access to our delightful community
              spaces, weekly gatherings, and a thriving online community Our
              Friendzo also gets you access to our delightful community spaces,
              weekly gatherings, and a thriving online community */}
                Discovering homes is effortless with Friendzo
              </p>
            </div>
            <div className="footer-box">
              <div className="footer2">
                <ul className="footer-list">
                  {/* <li>Blog</li> */}
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "#FFFFFF" }}
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "#FFFFFF" }}
                      to="/about-us"
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "#FFFFFF" }}
                      to="/contact-us"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "#FFFFFF" }}
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none", color: "#FFFFFF" }}
                      to="/cookie-policy"
                    >
                      Cookie Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer2">
                <ul className="footer-list">
                  {/* <li>Blog</li> */}
                  <li>
                    <h6 className="foot-title">Address :- </h6>
                    <p className="foot-text">
                      C-502, Fifth Floor, Royal Amar Greens, Niranjanpur,
                      Indore, indore, Madhya pradesh, india, 452010
                    </p>
                  </li>
                  <li>
                    <h6 className="foot-title">Contact:- </h6>
                    <p className="foot-text">+91 8962594672</p>
                  </li>
                  <li>
                    <h6 className="foot-title">Email :- </h6>
                    <p className="foot-text">ajaynagwani@friendzo.in </p>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="footer3">
              <ul>
                Loreum
                <li>Home</li>
                <li>Home</li>
                <li>Home</li>
                <li>Home</li>
              </ul>
            </div> */}
            {/* <div className="footer4">
              <ul>
                Loreum
                <li>Home</li>
                <li>Home</li>
                <li>Home</li>
                <li>Home</li>
              </ul>
            </div> */}
          </div>
          {/* <div className="footer-bottom">
          </div> */}
        </div>
        <div className="copy-text">
          <p>
            Copyright @ 2024. All rights reserved. Housingera Services Private
            Limited.
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
