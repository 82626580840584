import React, { useRef } from "react";
import Slider from "react-slick";
import photo1 from "../assets/photos/photo1.png";
import photo2 from "../assets/photos/photo2.png";
import photo3 from "../assets/photos/photo3.png";
import photo4 from "../assets/photos/photo4.png";
import photo5 from "../assets/photos/photo5.png";
import rightArrow from "../assets/right-arrow.png"
import leftArrow from "../assets/left-arrow.png"

function PhotosSlider() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    centerPadding: "270px",
    slidesToShow: 3,
    autoPlay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "10px",
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "10px",
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "10px",
        }
      }
    ]
  };
  return (
    <>
      <div className="slider-container">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          <div>
            <img src={photo1} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo2} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo3} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo4} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo5} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo2} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo3} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo1} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo4} alt="" className="photo-slide" />
          </div>
          <div>
            <img src={photo5} alt="" className="photo-slide" />
          </div>
        </Slider>
        <div style={{ textAlign: "center", marginTop: "45px" }}>
          <button className="button" onClick={previous} style={{border: "none", backgroundColor: "transparent"}}>
          <img src={leftArrow} alt="" className="photo-slide-" />
          </button>
          <button className="button" onClick={next} style={{border: "none", backgroundColor: "transparent"}}>
          <img src={rightArrow} alt="" className="photo-slide-" />
          </button>
        </div>
      </div>
    </>
  );
}

export default PhotosSlider;
