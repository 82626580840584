import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
// import award from "../assets/image-removebg-preview - 2023-12-01T160251 1.svg";
import closeImg from "../assets/close-btn.svg";
import yeah from "../assets/Yeah.svg";
import pie from "../assets/Ellipse 9.svg";
import tick from "../assets/Vector 1.svg";
import dish from "../assets/g/d1.svg";
import dish2 from "../assets/g/d2.svg";
import dish3 from "../assets/g/d3.svg";
import dish4 from "../assets/g/d4.svg";
import cancelImg from "../assets/cancel-btn.svg";
import submitImg from "../assets/submit-btn.svg";
// import wave from "../assets/Vector 6.svg";
import seeBtn from "../assets/see-btn.svg";
import property from "../assets/photos/p2.svg";
import property1 from "../assets/photos/p3.svg";
import property2 from "../assets/photos/p4.svg";
import property3 from "../assets/photos/p1.svg";
import icon from "../assets/icon (1).svg";
import icon2 from "../assets/icon (2).svg";
import icon3 from "../assets/icon (3).svg";
import Footer from "../Components/Footer";
// import ic from "../assets/icons (1).svg";
// import ic2 from "../assets/icons (2).svg";
// import ic3 from "../assets/icons (3).svg";
// import ic4 from "../assets/icons (4).svg";
// import ic5 from "../assets/icons (5).svg";
// import ic6 from "../assets/icons (6).svg";
import item2 from "../assets/g/item13.svg";
import item3 from "../assets/g/item14.svg";
// import item4 from "../assets/g/item4.svg";
import item5 from "../assets/g/item5.svg";
import item6 from "../assets/g/item6.svg";
import item7 from "../assets/g/item7.svg";
import item8 from "../assets/g/item8.svg";
import photo1 from "../assets/g/photo1.png";
import photo2 from "../assets/g/photo2.png";
import photo3 from "../assets/g/photo3.png";
import photo4 from "../assets/g/photo4.png";
import photo5 from "../assets/g/photo5.png";
import photo6 from "../assets/g/photo6.png";
import p1 from "../assets/g/p1.png";
import p2 from "../assets/g/p2.png";
import p3 from "../assets/g/p3.png";
import p4 from "../assets/g/p4.png";
import p5 from "../assets/g/p5.png";
import p6 from "../assets/g/p6.png";

import a1 from "../assets/g/a1.png";
import a2 from "../assets/g/a2.png";
import a3 from "../assets/g/a3.png";
import a4 from "../assets/g/a4.png";
import a5 from "../assets/g/a5.png";
import a6 from "../assets/g/a6.png";

import b1 from "../assets/g/b1.png";
import b2 from "../assets/g/b2.png";
import b3 from "../assets/g/b3.png";
import b4 from "../assets/g/b4.png";
import b5 from "../assets/g/b5.png";
import b6 from "../assets/g/b6.png";

import contactImg from "../assets/g/contact.svg";
// import item9 from "../assets/g/item9.svg";
// import item10 from "../assets/g/item11.svg";
// import item11 from "../assets/g/item12.svg";
// import ic8 from "../assets/g/item3.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import PhotosSlider from "../Components/PhotosSlider";
// import GSlider from "../Components/GSlider";
import Wave from "react-wavify";
import axios from "axios";
import { Link } from "react-router-dom";

function Home() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [isModalOpen3, setModalOpen3] = useState(false);
  const [isModalOpen4, setModalOpen4] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal1 = () => {
    setModalOpen1(true);
  };
  const closeModal1 = () => {
    setModalOpen1(false);
  };

  const openModal2 = () => {
    setModalOpen2(true);
  };
  const closeModal2 = () => {
    setModalOpen2(false);
  };

  const openModal3 = () => {
    setModalOpen3(true);
  };
  const closeModal3 = () => {
    setModalOpen3(false);
  };

  const openModal4 = () => {
    setModalOpen4(true);
  };
  const closeModal4 = () => {
    setModalOpen4(false);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="hero-section">
        <div className="hero-section-left">
          {/* <div data-aos="fade-right" className="award">
            <img src={awor} alt="awor" />
            <p>Award -wining co-living consultaion Firm</p>
          </div> */}
          <br />
          <br />
          <img src={yeah} alt="yeah" data-aos="fade-right" />
          <h1 data-aos="fade-right">Welcome Home to Friendzo:</h1>
          <h1 className="line2" data-aos="fade-right">
            <br /> Where Living is Thriving!
            {/* Your Space to Dream, */}
            {/* <br /> */}
            {/* Your Crew to Scream. */}
          </h1>
          <br />
          {/* <p data-aos="fade-right">
            A visit to your future home before you move in ,Hassle free stay in
            convenient locations a personal one on one tour of a property
          </p> */}
          <br />
          <br />
          <button
            style={{ cursor: "pointer" }}
            data-aos="fade-right"
            onClick={openModal}
          >
            Post Your Requirement
          </button>
        </div>
        <div className="hero-section-right">
          <div className="blue" data-aos="zoom-in-down">
            <br />
            <div className="orange" data-aos="zoom-in-left">
              <br />

              <div className="yellow" data-aos="zoom-in-right"></div>
            </div>
          </div>
          <div className="box">
            <div className="pie">
              <img src={pie} alt="pie" />
            </div>
            <div className="box-des">
              <h4>Zero Brokerage </h4>
              {/* <h5>15.89</h5>*/}
            </div>
            <div className="tick">
              <img src={tick} alt="tick" />
            </div>
          </div>
          <br />
          <div className="box2">
            <div className="pie">
              <img src={pie} alt="pie" />
            </div>
            <div className="box-des">
              <h4>Dynamic Community Culture</h4>
              {/* <h5>15.89</h5>  */}
            </div>
            <div className="tick">
              <img src={tick} alt="tick" />
            </div>
          </div>

          <div className="box3">
            <div className="pie">
              <img src={pie} alt="pie" />
            </div>
            <div className="box-des">
              <h4> Hassle Free Living</h4>
              {/*<h5>15.89</h5> */}
            </div>
            <div className="tick">
              <img src={tick} alt="tick" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="wave">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#FFD433"
            fill-opacity="1"
            d="M0,96L40,117.3C80,139,160,181,240,208C320,235,400,245,480,245.3C560,245,640,235,720,224C800,213,880,203,960,213.3C1040,224,1120,256,1200,245.3C1280,235,1360,181,1400,154.7L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
      </div> */}
      <div className="wave-item">
        <Wave
          fill="#ffd433"
          paused={false}
          style={{ display: "flex" }}
          options={{
            height: 20,
            amplitude: 20,
            speed: 0.15,
            points: 10,
          }}
        />
      </div>
      <div className="experience">
        {/* <div className="wave-overflow">
          <div className="wave-container">
            <div className="ocean">
              <div className="wave"></div>
              <div className="wave"></div>
            </div>
          </div>
        </div> */}
        <div data-aos="flip-up" data-aos-duration="1500">
          <i>
            <h1>Friendzo</h1>
            {/* <h2 style={{ marginTop: "30px" }}>Friendzo experience</h2> */}
            <h2 style={{ marginTop: "30px" }}>Living experience</h2>
          </i>
        </div>
        <br />
        <br /> <br />
        <br />
        <div className="exp-cards">
          <div className="exp-card">
            <div className="exp-card-img" data-aos="zoom-out">
              <img src={dish4} />
            </div>
            <br />

            <h3> Brokerage Free Accommodation</h3>
            <br />

            <p>
              Rent hassle-free with no brokerage fees or paperwork required at
              Friendzo.
            </p>
          </div>
          <div className="exp-card">
            <div className="exp-card-img" data-aos="zoom-out">
              <img src={dish3} />
            </div>
            <br />

            <h3> Secure and Modern Amenities</h3>
            {/* <h3>MOTIVATE</h3> */}
            <br />

            <p>
              Enjoy safety and convenience with advanced CCTV & Biometric
              security, fully furnished rooms, and high-speed WIFI.
            </p>
          </div>

          <div className="exp-card">
            <div className="exp-card-img" data-aos="zoom-out">
              <img src={dish2} />
            </div>
            <br />

            <h3>Community-Centric Living</h3>
            <br />

            <p>
              Engage in a vibrant community through events and social spaces
              designed for networking and interaction.
            </p>
          </div>
          <div className="exp-card">
            <div className="exp-card-img" data-aos="zoom-out">
              <img src={dish} />
            </div>
            <br />

            <h3>Convenient Location </h3>
            {/* <h3>. Gathering Hub</h3> */}
            <br />

            <p>
              Live conveniently in prime areas with easy access to
              transportation, shopping, dining, and entertainment options.
            </p>
          </div>
        </div>
      </div>

      {/* <br/>
<br/> */}

      <div className="habitat-section">
        <div id="rssBlock" className="exp-1">
          <p class="cnnContents">
            <span class="marqueeStyle">
              &nbsp;Your home, your friends, your Friendzo.
            </span>
            <span class="marqueeStyle2">
              &nbsp;Your home, your friends, your Friendzo.&nbsp;
            </span>
            <span class="marqueeStyle">
              &nbsp;Your home, your friends, your Friendzo.
            </span>
            <span class="marqueeStyle2">
              &nbsp;Your home, your friends, your Friendzo.&nbsp;
            </span>
          </p>
        </div>
        {/* <div className="habitat">
          <h1>Your home, your friends, your Friendzo.&nbsp;</h1>
          <h1>Your home, your friends, your Friendzo.&nbsp;</h1>
          <h1>Your home, your friends, your Friendzo.</h1>
        </div> */}
      </div>
      <div className="Amenities">
        <i>
          <h1 data-aos="fade-right">Amenities</h1>
          <br />
          <p data-aos="flip-up">
            Elevate Your Lifestyle: Where Every Amenity Feels Like Home.
            {/* Choose between co-living spaces, shared flats <br /> and private
            studios. We promise a superb <br /> housing experience that matches
            all your needs. */}
          </p>
        </i>

        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="Amenities-cards">
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item6} />
            <br />
            <br />
            {/* <p>Wellness Centre</p> */}
            <p>Biometric Security</p>
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item5} />
            <br />
            <br />
            <p>WiFi</p>
            {/* <p>Bistro</p> */}
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item8} />
            <br />
            <br />
            <p>Laundry</p>
            {/* <p>Game Room</p> */}
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item7} />
            <br />
            <br />
            <p>Free Housekeeping</p>
            {/* <p>Full-service Laundry</p> */}
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item3} />
            <br />
            <br />
            {/* <p>Super-fast WiFi</p> */}
            <p>Fully Furnished Rooms</p>
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src="/chill.png" style={{ height: "100px", width: "100px" }} />
            <br />
            <br />
            <p>Chill Zone</p>
            {/* <p>Food Truck</p> */}
          </div>
          {/* <div className="Amenities-card" data-aos="fade-right">
            <img src={item7} />
            <br />
            <br />
            <p>Caretaker 24/7</p>
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item8} />
            <br />
            <br />
            <p>clothes cleaner</p>
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item9} />
            <br />
            <br />
            <p>Cleaning & Maintenance</p>
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item10} />
            <br />
            <br />
            <p>Valet Trash</p>
          </div>
          <div className="Amenities-card" data-aos="fade-right">
            <img src={item11} />
            <br />
            <br />
            <p>Pay By App</p>
          </div> */}
        </div>
      </div>
      <div className="reverse-wave">
        <div className="wave-item">
          <Wave
            fill="#000000"
            paused={false}
            style={{ display: "flex" }}
            options={{
              height: 20,
              amplitude: 20,
              speed: 0.15,
              points: 10,
            }}
          />
        </div>
      </div>
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#000000"
          fill-opacity="1"
          d="M0,128L40,154.7C80,181,160,235,240,229.3C320,224,400,160,480,122.7C560,85,640,75,720,80C800,85,880,107,960,101.3C1040,96,1120,64,1200,64C1280,64,1360,96,1400,112L1440,128L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
        ></path>
      </svg> */}
      <div className="property">
        <div
          style={{
            paddingLeft: "50px",
            paddingTop: "50px",
          }}
        >
          <h6
            className="photos-title"
            style={{ textAlign: "left", marginBottom: "20px" }}
          >
            Property
          </h6>
        </div>
        <div className="property-cards">
          <div
            onClick={openModal1}
            className="property-card"
            data-aos="flip-up"
          >
            <img alt="property" className="pro-img" src={property} />
            <br />
            <br />

            {/* <h2>
              <span>$2,095/</span>Month
            </h2>
            <br /> */}

            <h3>Sapphire</h3>
            <br />

            <p>2699 Green Velley Highland Lake,FL</p>
            <br />
            <div className="abc">
              <img src={icon3} alt="icon" />
              <p>1 BHK</p>
              <img src={icon3} alt="icon" />
              <p>3 BHK</p>
              <img src={icon3} alt="icon" />
              <p>4 BHK</p>
            </div>
          </div>
          <div
            onClick={openModal2}
            className="property-card"
            data-aos="flip-right"
          >
            <img className="pro-img" alt="property" src={property1} />
            {/* <br /> */}
            <br />

            {/* <h2>
              <span>$2,095/</span>Month
            </h2> */}
            <br />

            <h3>Aqua</h3>
            <br />

            <p>2699 Green Velley Highland Lake,FL</p>
            <br />
            <div className="abc">
              <img src={icon3} alt="icon" />
              <p>1 BHK</p>
              <img src={icon3} alt="icon" />
              <p>3 BHK</p>
              <img src={icon3} alt="icon" />
              <p>4 BHK</p>
            </div>
          </div>
          <div
            onClick={openModal3}
            className="property-card"
            data-aos="flip-up"
          >
            <img className="pro-img" alt="property" src={property2} />
            <br />
            <br />

            {/* <h2>
              <span>$2,095/</span>Month
            </h2>
            <br /> */}

            <h3>Olive</h3>
            <br />

            <p>2699 Green Velley Highland Lake,FL</p>
            <br />
            <div className="abc">
              <img src={icon3} alt="icon" />
              <p>1 BHK</p>
              <img src={icon3} alt="icon" />
              <p>3 BHK</p>
              <img src={icon3} alt="icon" />
              <p>4 BHK</p>
            </div>
          </div>
          <div
            onClick={openModal4}
            className="property-card"
            data-aos="flip-right"
          >
            <img className="pro-img" alt="property" src={property3} />
            <br />
            <br />

            {/* <h2>
              <span>$2,095/</span>Month
            </h2>
            <br /> */}

            <h3>Skyz</h3>
            <br />

            <p>2699 Green Velley Highland Lake,FL</p>
            <br />
            <div className="abc">
              <img src={icon3} alt="icon" />
              <p>1 BHK</p>
              <img src={icon3} alt="icon" />
              <p>3 BHK</p>
              <img src={icon3} alt="icon" />
              <p>4 BHK</p>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingLeft: "50px",
          }}
        >
          <button style={{ border: "none", background: "transparent" }}>
            <img src={seeBtn} alt="" />
          </button>
        </div>
        {/* <div
          className="hero-section-left"
          style={{
            padding: "0",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <button>Explore Now!</button>
        </div> */}
      </div>
      <div className="photos-section">
        <h6 className="photos-title">Community Photos</h6>
        <PhotosSlider />
      </div>
      <div className="Explore">
        <h1 data-aos="fade-right"> gallery</h1>
        <br />
        <br />
        <br />

        <div className="Explore-boxs">
          <div className="Explore-box1" data-aos="fade-right"></div>
          <div className="Explore-box2" data-aos="fade-left"></div>
        </div>
      </div>
      <div className="habitat-section exp">
        <div id="rssBlock" className="exp2">
          <p class="cnnContents">
            <span class="marqueeStyle">
              &nbsp;Live like locals, connect like globals.
            </span>
            <span class="marqueeStyle2">
              &nbsp;Live like locals, connect like globals.&nbsp;
            </span>
            <span class="marqueeStyle">
              &nbsp;Live like locals, connect like globals.
            </span>
            <span class="marqueeStyle2">
              &nbsp;Live like locals, connect like globals.&nbsp;
            </span>
          </p>
        </div>
        {/* <div className="habitat exp2">
          <h1>Live like locals, connect like globals.&nbsp;</h1>
          <h1>Live like locals, connect like globals.&nbsp;</h1>
          <h1>Live like locals, connect like globals.</h1>
        </div> */}
      </div>

      {/* <div className="wave-item">
        <Wave
          fill="#000000"
          paused={false}
          style={{ display: "flex" }}
          options={{
            height: 40,
            amplitude: 40,
            speed: 0.15,
            points: 10,
          }}
        />
      </div> */}
      <Footer />
      <CustomModal isOpen={isModalOpen} onClose={closeModal} />
      <Property1 isOpen1={isModalOpen1} onClose1={closeModal1} />
      <Property2 isOpen2={isModalOpen2} onClose2={closeModal2} />
      <Property3 isOpen3={isModalOpen3} onClose3={closeModal3} />
      <Property4 isOpen4={isModalOpen4} onClose4={closeModal4} />
    </>
  );
}

export default Home;

const CustomModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    contactNumber: "",
    altContactNumber: "",
    email: "",
    preferableLocation: "",
    typeOfProperty: "",
    tentativeBudget: "",
    dataOfShifting: "",
    numberOfTenants: "",
    tenentType: "",
    comesFrom: [],
    feedback: "",
  });

  const containsOther = formData?.comesFrom.some(
    (item) => item.toLowerCase() === "other"
  );
  const backendURL = "https://friendzoapi.vercel.app/";

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handle checkbox changes
      if (checked) {
        // If checkbox is checked, add it to the array
        setFormData({
          ...formData,
          comesFrom: [...formData.comesFrom, value],
        });
      } else {
        // If checkbox is unchecked, remove it from the array
        setFormData({
          ...formData,
          comesFrom: formData.comesFrom.filter((source) => source !== value),
        });
      }
    } else {
      // Handle other input changes
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post(
        `${backendURL}api/user/bookuser`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        console.log("Success:", response.data);
        onClose();
        alert("Book Request Sent !");
      } else {
        console.error("Error:", response.status, response.statusText);
        alert("Request Not Sent !");
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert("Request Not Sent !");
    }
  };
  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <button className="close-button" onClick={onClose}>
              <img src={closeImg} alt="" />
            </button>
          </div>
          <div className="modal-title-box">
            <h6 className="modal-title">
              Get Your Home @ 0% Brokerage With Friendzo
            </h6>
          </div>
          <div className="modal-form-container">
            <div className="modal-input-box">
              <p className="modal-input-title">Full Name :</p>
              <input
                type="text"
                className="modal-input"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>
            <div className="modal-input-box">
              <p className="modal-input-title">Contact Number :</p>
              <input
                type="number"
                className="modal-input"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="modal-input-box">
              <p className="modal-input-title">
                Alternative Number ( WhatsApp Number) :
              </p>
              <input
                type="text"
                className="modal-input"
                name="altContactNumber"
                value={formData.altContactNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="modal-input-box">
              <p className="modal-input-title">Email ID :</p>
              <input
                type="email"
                className="modal-input"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="modal-input-box">
              <p className="modal-input-title">Preferable Location :</p>
              <select
                name="preferableLocation"
                className="modal-select"
                id=""
                value={formData.preferableLocation}
                onChange={handleInputChange}
                required
              >
                <option value="Studio Room">Select Location</option>
                <option value="Vijay Nagar">Vijay Nagar</option>
                <option value="1 BHK">Mahalaxmi Nagar</option>
                <option value="2 BHK">Other</option>
              </select>
            </div>
            <div className="modal-input-box">
              <p className="modal-input-title">Type of Property :</p>
              <select
                name="typeOfProperty"
                className="modal-select"
                id=""
                value={formData.typeOfProperty}
                onChange={handleInputChange}
                required
              >
                <option value="Studio Room">Studio Room</option>
                <option value="1 RK (Studio Room with Kitchen)">
                  1 RK (Studio Room with Kitchen)
                </option>
                <option value="1 BHK">1 BHK</option>
                <option value="2 BHK">2 BHK</option>
                <option value="3 BHK">3 BHK</option>
                <option value="4 BHK">4 BHK</option>
                <option value="PG">PG</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="modal-input-box">
              <p className="modal-input-title">
                Kindly Share your tentative Budget :
              </p>
              <input
                type="number"
                className="modal-input"
                name="tentativeBudget"
                value={formData.tentativeBudget}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="modal-input-box">
              <p className="modal-input-title">Number of Tenants :</p>
              <input
                type="number"
                className="modal-input"
                name="numberOfTenants"
                value={formData.numberOfTenants}
                onChange={handleInputChange}
              />
            </div>
            <div className="modal-input-box">
              <p className="modal-input-title">Date of Shifting :</p>
              <input
                type="date"
                className="modal-input"
                name="dataOfShifting"
                value={formData.dataOfShifting}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="modal-input-box">
              <p className="modal-input-title">Tenants type :</p>
              <select
                className="modal-select"
                id=""
                name="tenentType"
                value={formData.tenentType}
                onChange={handleInputChange}
                required
              >
                <option value="">Choose</option>
                <option value="Male (Student)">Male (Student)</option>
                <option value="Female (Student)">Female (Student)</option>
                <option value="Bachelors(Male - Working)">
                  Bachelors(Male - Working)
                </option>
                <option value="Bachelors(Female - Working)">
                  Bachelors(Female - Working)
                </option>
                <option value="Couples">Couples</option>
                <option value="Family">Family</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div className="submit-btn-bottom">
            <div className="modal-input-box">
              <p className="modal-input-title">
                How do you know about Friendzo :
              </p>
            </div>
            <div className="modal-check-box">
              <div className="modal-checkbox">
                <input
                  type="checkbox"
                  name="Instagram"
                  id="checkInstagram"
                  value="Instagram"
                  checked={formData.comesFrom.includes("Instagram")}
                  onChange={handleInputChange}
                />
                <label htmlFor="check1">Instagram</label>
              </div>
              <div className="modal-checkbox">
                <input
                  type="checkbox"
                  name="Facebook"
                  id="checkFacebook"
                  value="Facebook"
                  checked={formData.comesFrom.includes("Facebook")}
                  onChange={handleInputChange}
                />
                <label htmlFor="check1">Facebook</label>
              </div>
              <div className="modal-checkbox">
                <input
                  type="checkbox"
                  name="Friends"
                  id="checkFriends"
                  value="Friends"
                  checked={formData.comesFrom.includes("Friends")}
                  onChange={handleInputChange}
                />
                <label htmlFor="check1">Friends</label>
              </div>
              <div className="modal-checkbox">
                <input
                  type="checkbox"
                  name="Posters Ads"
                  id="checkPostersAds"
                  value="Posters Ads"
                  checked={formData.comesFrom.includes("Posters Ads")}
                  onChange={handleInputChange}
                />
                <label htmlFor="check1">Posters Ads</label>
              </div>
              <div className="modal-checkbox">
                <input
                  type="checkbox"
                  name="Other"
                  id="checkOther"
                  value="Other"
                  checked={formData.comesFrom.includes("Other")}
                  onChange={handleInputChange}
                />
                <label htmlFor="checkOther">Other: </label>
                {containsOther && (
                  <input
                    type="text"
                    className="modal-input"
                    name="feedback"
                    value={formData.feedback}
                    onChange={handleInputChange}
                  />
                )}
                {/* <div
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  <input type="text" className="modal-input" />
                </div> */}
              </div>
            </div>
            <div className="modal-btn">
              <button className="submit-btn" type="submit">
                <img src={cancelImg} alt="" />
              </button>
              <button className="submit-btn" onClick={onClose}>
                <img src={submitImg} alt="" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const Property1 = ({ isOpen1, onClose1 }) => {
  return (
    <div className={`modal ${isOpen1 ? "open" : ""}`}>
      <div className="modal-content">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button className="close-button" onClick={onClose1}>
            <img src={closeImg} alt="" />
          </button>
        </div>
        <div className="modal-card">Sapphire</div>
        <div className="modal-card-container">
          <div>
            <img src={photo1} alt="" />
          </div>
          <div>
            <img src={photo2} alt="" />
          </div>
          <div>
            <img src={photo3} alt="" />
          </div>
          <div>
            <img src={photo4} alt="" />
          </div>
          <div>
            <img src={photo5} alt="" />
          </div>
          <div>
            <img src={photo6} alt="" />
          </div>
        </div>
        <button
          style={{
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <Link to="/contact-us">
            <img src={contactImg} alt="" />
          </Link>
        </button>
      </div>
    </div>
  );
};
const Property2 = ({ isOpen2, onClose2 }) => {
  return (
    <div className={`modal ${isOpen2 ? "open" : ""}`}>
      <div className="modal-content">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button className="close-button" onClick={onClose2}>
            <img src={closeImg} alt="" />
          </button>
        </div>
        <div className="modal-card">Aqua</div>
        <div className="modal-card-container">
          <div>
            <img src={p1} alt="" />
          </div>
          <div>
            <img src={p2} alt="" />
          </div>
          <div>
            <img src={p3} alt="" />
          </div>
          <div>
            <img src={p4} alt="" />
          </div>
          <div>
            <img src={p5} alt="" />
          </div>
          <div>
            <img src={p6} alt="" />
          </div>
        </div>
        <button
          style={{
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <Link to="/contact-us">
            <img src={contactImg} alt="" />
          </Link>
        </button>
      </div>
    </div>
  );
};

const Property3 = ({ isOpen3, onClose3 }) => {
  return (
    <div className={`modal ${isOpen3 ? "open" : ""}`}>
      <div className="modal-content">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button className="close-button" onClick={onClose3}>
            <img src={closeImg} alt="" />
          </button>
        </div>
        <div className="modal-card">Olive</div>
        <div className="modal-card-container">
          <div>
            <img src={a1} alt="" />
          </div>
          <div>
            <img src={a2} alt="" />
          </div>
          <div>
            <img src={a3} alt="" />
          </div>
          <div>
            <img src={a4} alt="" />
          </div>
          <div>
            <img src={a5} alt="" />
          </div>
          <div>
            <img src={a6} alt="" />
          </div>
        </div>
        <button
          style={{
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <Link to="/contact-us">
            <img src={contactImg} alt="" />
          </Link>
        </button>
      </div>
    </div>
  );
};
const Property4 = ({ isOpen4, onClose4 }) => {
  return (
    <div className={`modal ${isOpen4 ? "open" : ""}`}>
      <div className="modal-content">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button className="close-button" onClick={onClose4}>
            <img src={closeImg} alt="" />
          </button>
        </div>
        <div className="modal-card">Skyz</div>
        <div className="modal-card-container">
          <div>
            <img src={b1} alt="" />
          </div>
          <div>
            <img src={b2} alt="" />
          </div>
          <div>
            <img src={b3} alt="" />
          </div>
          <div>
            <img src={b4} alt="" />
          </div>
          <div>
            <img src={b5} alt="" />
          </div>
          <div>
            <img src={b6} alt="" />
          </div>
        </div>
        <button
          style={{
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <Link to="/contact-us">
            <img src={contactImg} alt="" />
          </Link>
        </button>
      </div>
    </div>
  );
};
