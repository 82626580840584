import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function Faq() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <div className="terms-section">
        <h6 className="terms-title">Faq </h6>
      </div>
      <div className="term-content">
        <h6 className="terms-c-title">
          What amenities do you provide in your properties?
        </h6>
        <p className="terms-text">
          Our properties come fully furnished and equipped with amenities such
          as air conditioning, refrigerator, RO (Reverse Osmosis) water
          purifier, and washing machine.
        </p>

        <h6 className="terms-c-title">
          {" "}
          How is security ensured at your properties?
        </h6>
        <p className="terms-text">
          We prioritize the safety of our residents by implementing CCTV cameras
          surrounding the property and on every floor. Additionally, we
          usebiometric locks for extra security.
        </p>
        <h6 className="terms-c-title">
          What support do you offer to residents?
        </h6>
        <p className="terms-text">
          We provide Assistance to address any concerns or issues that residents
          may have during their stay.
        </p>
        <h6 className="terms-c-title">
          How can I book a room in one of your properties?
        </h6>
        <p className="terms-text">
          You can book a room by contacting us directly through our website or
          by messaging us on our whatsapp number. Our team will assist you in
          finding the perfect accommodation for your needs.
        </p>
        <h6 className="terms-c-title">
          Do you offer short-term or long-term leases?
        </h6>
        <p className="terms-text">
          We offer both short-term and long-term lease options to accommodate
          different preferences and needs.
        </p>
        <h6 className="terms-c-title">
          Is parking available at your properties?
        </h6>
        <p className="terms-text">
          Yes, we provide parking facilities for residents either on-site or
          nearby, depending on the location of the property.
        </p>
        <h6 className="terms-c-title">
          What happens if I have a maintenance issue in my unit?
        </h6>
        <p className="terms-text">
          We have a dedicated maintenance team available to address any issues
          that may arise during your stay. Simply submit a maintenance request,
          and we'll promptly attend to it.
        </p>
        <h6 className="terms-c-title">Is there a security deposit required?</h6>
        <p className="terms-text">
          Yes, we require a security deposit upon signing the lease agreement.
          This deposit is refundable, subject to the terms outlined in the lease
          agreement.
        </p>
        <h6 className="terms-c-title">
          How do I terminate my lease agreement early if needed?
        </h6>
        <p className="terms-text">
          Early lease termination policies vary depending on the terms of your
          lease agreement.
          <br />
          Please refer to your lease agreement or contact our team for further
          assistance if you need to terminate your lease early.
        </p>
        <p className="terms-text">
          # Do you provide any cleaning services for residents?
        </p>
        <p className="terms-text">
          Yes, we provide complimentary room cleaning services to all our
          residents. Our dedicated housekeeping team ensures that your
          individual living spaces and shared spaces are regularly cleaned and
          maintained, allowing you to focus on enjoying your living experience
          without the hassle of cleaning chores.
        </p>
        <h6 className="terms-c-title">
          What utilities and services are included in the rent?
        </h6>
        <p className="terms-text" style={{ marginBottom: "100px" }}>
          Rent includes not only fully furnished accommodations but also
          complimentary utilities such as water, and high-speed WiFi.
          Additionally, we provide free housekeeping services to ensure a clean
          and comfortable living environment for our resident
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Faq;
