import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import AboutImg from "../assets/About.svg";
// import submitImg from "../assets/sub-img.svg";
import Footer from "../Components/Footer";
// import Item1 from "../assets/photos/item1.svg";
// import Item2 from "../assets/photos/item2.svg";
// import Item3 from "../assets/photos/item3.svg";
import photo1 from "../assets/photos/photo6.png";
import photo2 from "../assets/photos/photo7.png";
import photo3 from "../assets/photos/photo9.png";
import contactImg from "../assets/photos/contact-btn.svg";
import Wave from "react-wavify";

function AboutUs() {
  const [selectedOption, setSelectedOption] = useState("1");

  const handleSelectOption = (option) => {
    setSelectedOption((prevOption) => (prevOption === option ? null : option));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <div className="ab-section">
        {/* <div className="blue" data-aos="zoom-in-down">
          <br />
          <div className="orange" data-aos="zoom-in-left">
            <br />

            <div className="yellow" data-aos="zoom-in-right"></div>
          </div>
        </div> */}
        <div className="about-section">
          <img src={AboutImg} alt="" />
          <div className="about-box">
            <p className="about-text">
              Welcome to Friendzo, where finding your ideal home comes with zero
              brokerage fees. We're redefining the rental experience by offering
              personalized and hassle-free solutions. At Friendzo, we are proud
              to offer our properties with a 0% brokerage fee. Say goodbye to
              unnecessary costs and enjoy a straightforward leasing process that
              saves you time and money. Our commitment to service excellence
              ensures that every step of your journey, from property search to
              moving in, is met with exceptional care and attention.
            </p>
            <p className="about-text">
              Trust is the foundation of our relationship with clients. At
              Friendzo, you'll experience transparent and honest dealings at
              every stage, ensuring you have a clear understanding of the terms
              and conditions associated with your rental. Your comfort and
              satisfaction are at the forefront of what we do. Our
              customer-centric approach means we listen to your needs, address
              concerns promptly, and strive to make your experience with
              Friendzo memorable.
            </p>
          </div>
        </div>
      </div>
      <div className="add-section">
        <div className="add-left">
          <div className="add-box">
            <h6 className="add-title">
              Zero brokerage guaranteed- embrace hassle-free leasing process
              with zero brokerage.
            </h6>
            {/* <p className="add-text">
              Loreum ISpumLoreum ISpum Loreum ISpum Loreum ISpum Loreum ISpum
              Loreum ISpumLoreum .
            </p> */}
          </div>
          <div className="add-box">
            <h6 className="add-title">
              Transparent process – experience honest and clear dealings at
              every stage.
            </h6>
            {/* <p className="add-text">
              Loreum ISpumLoreum ISpum Loreum ISpum Loreum ISpum Loreum ISpum
              Loreum ISpumLoreum .
            </p> */}
          </div>
          <div className="add-box">
            <h6 className="add-title">
              Customer care – your comfort and satisfaction is our priority.
            </h6>
            {/* <p className="add-text">
              Loreum ISpumLoreum ISpum Loreum ISpum Loreum ISpum Loreum ISpum
              Loreum ISpumLoreum .
            </p> */}
          </div>
        </div>
        <div className="add-right">
          <h6 className="fd-title">Friendzo</h6>
          <p className="fd-sub-title">Redefine luxurious living with Us.</p>
          <p className="fd-text">
            Elevate your lifestyle with our rental properties that boast luxury
            amenities. From state-of-the-art facilities to stylish interiors, we
            provide homes that not only meet your needs but exceed your
            expectations.
          </p>
          {/* <div className="project-box">
            <div className="project-item">
              <div className="count-box">
                <img src={Item1} alt="" />
                <p className="count-num">160+</p>
              </div>
              <p className="count-title">Projects Dione</p>
            </div>
            <div className="project-item">
              <div className="count-box">
                <img src={Item2} alt="" />
                <p className="count-num">80+</p>
              </div>
              <p className="count-title">Happy Clients</p>
            </div>
            <div className="project-item">
              <div className="count-box">
                <img src={Item3} alt="" />
                <p className="count-num">8+</p>
              </div>
              <p className="count-title">Years</p>
            </div>
          </div> */}
        </div>

        <div id="rssBlock2" className="exp-1">
          <p class="cnnContents">
            <span class="marqueeStyle">
              &nbsp;Community is our habitat...Community
            </span>
            <span class="marqueeStyle2">
              &nbsp;Community is our habitat...Community&nbsp;
            </span>
            <span class="marqueeStyle">
              &nbsp;Community is our habitat...Community
            </span>
            <span class="marqueeStyle2">
              &nbsp;Community is our habitat...Community&nbsp;
            </span>
          </p>
        </div>
      </div>
      <div className="who-section">
        <h6 className="fdd-title">Who We Are</h6>
        <div className="who-box">
          <div className="who-left">
            <img src={photo1} alt="" />
            {/* <h6 className="who-title">Add two lines of heading here</h6> */}
            <p className="who-text">
              With a commitment to exceptional service and a focus on your
              needs, we're here to make your rental experience seamless and
              enjoyable. Our zero brokerage guarantee, diverse range of
              properties, and dedication to transparency ensure that you find
              not just a place to live, but a place to call home. Welcome to
              Friendzo, where finding your ideal living space is simple,
              hassle-free, and tailored to you.
            </p>
            <button className="contact-btn">
              <img src={contactImg} alt="" />
            </button>
          </div>
          <div className="who-right">
            <img src={photo2} alt="" />
          </div>
        </div>
      </div>
      {/* <div className="story-section">
        <h6 className="fd-title" style={{ color: "#FFF" }}>
          Friendzo
        </h6>
        <p className="fd-sub-title" style={{ color: "#FFF" }}>
          Our Story
        </p>
        <div className="out-text-box">
          <p className="our-text">
            Wish to get a website or mobile app Developed. Let us handle
            everything for you. Palmspire is the one stop solution for all your
            technical and managerial burdlies when developing a Mobile or Web
            Application. We will Plan, Design, Develop, Test and Deploy your
            idea using your desired or our recommended technology stack while
            moking sure you get the Quality Product without Outspending your
            budget. g your desired or our recommended technology stack while
            moking sure you get the Quality Product without Outspending your
            budget
          </p>
          <p className="our-text">
            Wish to get a website or mobile app Developed. Let us handle
            everything for you. Palmspire is the one stop solution for all your
            technical and managerial burdlies when developing a Mobile or Web
            Application. We will Plan, Design, Develop, Test and Deploy your
            idea using your desired or.
          </p>
        </div>
      </div> */}
      {/* <div className="reverse-wave">
        <div className="wave-item">
          <Wave
            fill="#000000"
            paused={false}
            style={{ display: "flex" }}
            options={{
              height: 20,
              amplitude: 20,
              speed: 0.15,
              points: 10,
            }}
          />
        </div>
      </div> */}
      <div className="faq-section">
        <h6 className="fd-title">Friendzo</h6>
        <p className="fd-sub-title">Frequently Asked Questions</p>
        <div className="faq-box">
          <div className="faq-left">
            <img src={photo3} alt="" />
          </div>
          <div className="faq-right">
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "1" ? "20px" : "0" }}
                onClick={() => handleSelectOption("1")}
              >
                <h6 className="faq-text">
                  What amenities do you provide in your properties?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "1" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "1" ? "104px" : "0" }}
              >
                Our properties come fully furnished and equipped with amenities
                such as air conditioning, refrigerator, RO (Reverse Osmosis)
                water purifier, and washing machine.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "2" ? "20px" : "0" }}
                onClick={() => handleSelectOption("2")}
              >
                <h6 className="faq-text">
                  How is security ensured at your properties?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "2" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "2" ? "104px" : "0" }}
              >
                We prioritize the safety of our residents by implementing CCTV
                cameras surrounding the property and on every floor.
                Additionally, we usebiometric locks for extra security.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "3" ? "20px" : "0" }}
                onClick={() => handleSelectOption("3")}
              >
                <h6 className="faq-text">
                  What support do you offer to residents?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "3" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "3" ? "104px" : "0" }}
              >
                We provide Assistance to address any concerns or issues that
                residents may have during their stay.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "4" ? "20px" : "0" }}
                onClick={() => handleSelectOption("4")}
              >
                <h6 className="faq-text">
                  How can I book a room in one of your properties?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "4" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "4" ? "104px" : "0" }}
              >
                You can book a room by contacting us directly through our
                website or by messaging us on our whatsapp number. Our team will
                assist you in finding the perfect accommodation for your needs.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "5" ? "20px" : "0" }}
                onClick={() => handleSelectOption("5")}
              >
                <h6 className="faq-text">
                  Do you offer short-term or long-term leases?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "5" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "5" ? "104px" : "0" }}
              >
                We offer both short-term and long-term lease options to
                accommodate different preferences and needs.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "6" ? "20px" : "0" }}
                onClick={() => handleSelectOption("6")}
              >
                <h6 className="faq-text">
                  Is parking available at your properties?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "6" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "6" ? "104px" : "0" }}
              >
                Yes, we provide parking facilities for residents either on-site
                or nearby, depending on the location of the property.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "6" ? "20px" : "0" }}
                onClick={() => handleSelectOption("6")}
              >
                <h6 className="faq-text">
                  What happens if I have a maintenance issue in my unit?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "6" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "6" ? "104px" : "0" }}
              >
                We have a dedicated maintenance team available to address any
                issues that may arise during your stay. Simply submit a
                maintenance request, and we'll promptly attend to it.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "6" ? "20px" : "0" }}
                onClick={() => handleSelectOption("6")}
              >
                <h6 className="faq-text">
                  Is there a security deposit required?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "6" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "6" ? "104px" : "0" }}
              >
                Yes, we require a security deposit upon signing the lease
                agreement. This deposit is refundable, subject to the terms
                outlined in the lease agreement.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "6" ? "20px" : "0" }}
                onClick={() => handleSelectOption("6")}
              >
                <h6 className="faq-text">
                  How do I terminate my lease agreement early if needed?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "6" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "6" ? "104px" : "0" }}
              >
                Early lease termination policies vary depending on the terms of
                your lease agreement. <br />
                Please refer to your lease agreement or contact our team for
                further assistance if you need to terminate your lease early.{" "}
                <br /># Do you provide any cleaning services for residents?{" "}
                <br />
                Yes, we provide complimentary room cleaning services to all our
                residents. Our dedicated housekeeping team ensures that your
                individual living spaces and shared spaces are regularly cleaned
                and maintained, allowing you to focus on enjoying your living
                experience without the hassle of cleaning chores.
              </p>
            </div>
            <div className="faq-item">
              <div
                className="faq-drop"
                style={{ paddingBottom: selectedOption === "6" ? "20px" : "0" }}
                onClick={() => handleSelectOption("6")}
              >
                <h6 className="faq-text">
                  What utilities and services are included in the rent?
                </h6>
                <h6 className="faq-plush">
                  {selectedOption === "6" ? "-" : "+"}
                </h6>
              </div>
              <p
                className="faq-sub-text"
                style={{ height: selectedOption === "6" ? "104px" : "0" }}
              >
                Rent includes not only fully furnished accommodations but also
                complimentary utilities such as water, and high-speed WiFi.
                Additionally, we provide free housekeeping services to ensure a
                clean and comfortable living environment for our resident
              </p>
            </div>
          </div>
        </div>
      </div>
       <div className="reverse-wave">
        <div className="wave-item">
          <Wave
            fill="#024AB9"
            paused={false}
            style={{ display: "flex" }}
            options={{
              height: 20,
              amplitude: 20,
              speed: 0.15,
              points: 10,
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
