import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <nav>
        <div className={`navbar ${isMenuOpen ? "open" : ""}`} id="nava">
          {/* <div className="hamburger" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div> */}
          <Link to="/">
            <img src={logo} className="home-logo" alt="Logo" />
          </Link>
        </div>

        {/* <ul className={isMenuOpen ? "open" : ""}>
          <li>
            <Link to="/" className="list-item">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about-us" className="list-item">
              About US
            </Link>
          </li>
          <li>
            <Link to="/contact-us" className="list-item">
              Contact Us
            </Link>
          </li>
        </ul> */}
      </nav>
    </>
  );
}

export default Navbar;
